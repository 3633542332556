import React from "react";
import TCaption from "./TCaption.js";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const Icons = {
  Chairs: require("../img/icons/home/chairs.png"),
  Braintronics: require("../img/icons/home/braintronics.png"),
  Cushions: require("../img/icons/home/cushions.png"),
  FootMassagers: require("../img/icons/home/foot_massagers.png"),
  Health: require("../img/icons/home/health.png"),
  Massagers: require("../img/icons/home/massagers.png"),
  Mati: require("../img/icons/home/mati.png"),
  TermoMati: require("../img/icons/home/termomati.png"),
  Pillows: require("../img/icons/home/pillows.png"),
  Tables: require("../img/icons/home/tables.png"),
  Fitness: require("../img/icons/home/fitness.png"),
};

function CatalogSections(props) {
  return (
    <div className="__CatalogSections">
      <TCaption.Wrapper center>
        <TCaption.Title style={{ lineHeight: "34px" }}>Каталог</TCaption.Title>
        <TCaption.Title
          style={{
            color: "#000000",
            paddingTop: "0",
            fontWeight: "normal",
            lineHeight: "34px",
          }}
        >
          товаров
        </TCaption.Title>
        <TCaption.Spacer height="60px" />
      </TCaption.Wrapper>
      <Container>
        <Row>
          <Col xs="6">
            <Link to="/chairs/">
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${Icons.Chairs})` }}
              />
            </Link>
            <div className="button-wrapper">
              <Link to="/chairs/" className="button-detail red-black hl-red">
                Массажные кресла
              </Link>
            </div>
          </Col>
          <Col xs="6">
            <Link to="/braintronics/">
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${Icons.Braintronics})` }}
              />
            </Link>
            <div className="button-wrapper">
              <Link
                to="/braintronics/"
                className="button-detail red-black hl-red"
              >
                Массаж мозга Braintronics
              </Link>
            </div>
          </Col>
          <Col xs="6">
            <Link to="/massazhery-dlya-nog/">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Icons.FootMassagers})`,
                }}
              />
            </Link>
            <div className="button-wrapper">
              <Link
                to="/massazhery-dlya-nog/"
                className="button-detail red-black hl-red"
              >
                Массажёры для ног
              </Link>
            </div>
          </Col>
          <Col xs="6">
            <Link to="/cushions/">
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${Icons.Cushions})` }}
              />
            </Link>
            <div className="button-wrapper">
              <Link to="/cushions/" className="button-detail red-black hl-red">
                Массажные накидки
              </Link>
            </div>
          </Col>
          <Col xs="6">
            <Link to="/pillows/">
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${Icons.Pillows})` }}
              />
            </Link>
            <div className="button-wrapper">
              <Link to="/pillows/" className="button-detail red-black hl-red">
                Массажные подушки
              </Link>
            </div>
          </Col>
          <Col xs="6">
            <Link to="/hand_massagers/">
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${Icons.Massagers})` }}
              />
            </Link>
            <div className="button-wrapper">
              <Link
                to="/hand_massagers/"
                className="button-detail red-black hl-red"
              >
                Ручные массажёры
              </Link>
            </div>
          </Col>
          <Col xs="6">
            <Link to="/termomati/">
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${Icons.TermoMati})` }}
              />
            </Link>
            <div className="button-wrapper">
              <Link to="/termomati/" className="button-detail red-black hl-red">
                Термические маты
              </Link>
            </div>
          </Col>
          <Col xs="6">
            <Link to="/mati/">
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${Icons.Mati})` }}
              />
            </Link>
            <div className="button-wrapper">
              <Link to="/mati/" className="button-detail red-black hl-red">
                Массажные маты
              </Link>
            </div>
          </Col>
          <Col xs="6">
            <Link to="/health/">
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${Icons.Health})` }}
              />
            </Link>
            <div className="button-wrapper">
              <Link to="/health/" className="button-detail red-black hl-red">
                Товары для здоровья
              </Link>
            </div>
          </Col>
          <Col xs="6">
            <Link to="/tables/">
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${Icons.Tables})` }}
              />
            </Link>
            <div className="button-wrapper">
              <Link to="/tables/" className="button-detail red-black hl-red">
                Массажные столы
              </Link>
            </div>
          </Col>
          <Col xs="12">
            <Link to="/fitness/">
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${Icons.Fitness})` }}
              />
            </Link>
            <div className="button-wrapper">
              <Link
                style={{ width: "160px" }}
                to="/fitness/"
                className="button-detail red-black hl-red"
              >
                Фитнес-приборы
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CatalogSections;
